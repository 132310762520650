import './src/css/global.css';
import React from "react"
import { CartProvider } from "react-use-cart";
import { QuoteProvider } from './src/components/quote_context';
import { CartContextProvider } from './src/components/cart_context';

export const wrapRootElement = ({ element }) => 
(
  <CartProvider>
    <QuoteProvider>
      <CartContextProvider>
        {element}
      </CartContextProvider>
    </QuoteProvider>
  </CartProvider>
)
