import * as React from "react"
import { useState } from 'react'

const QuoteDefaultState = false;
const QuoteContext = React.createContext(QuoteDefaultState);

export function QuoteProvider({children})
{
  const [open, setOpen] = useState(false)

  return <QuoteContext.Provider value={[open, setOpen]}>
    {children}
  </QuoteContext.Provider>
}

export default QuoteContext;